import { initializeApp } from '@firebase/app';
import {
  isSupported as isFirebaseSupported,
  deleteToken as deleteFirebaseToken,
  getMessaging,
  getToken as getFirebaseToken,
  onMessage as onFirebaseMessage,
  MessagePayload,
  NextFn,
  Observer,
  Unsubscribe,
  Messaging,
} from '@firebase/messaging';
import { getDatabase, Database } from '@firebase/database';
import { FirebaseStorage, getStorage } from '@firebase/storage';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
let messaging: Messaging = null;
let firebaseDb: Database = null;
let storage: FirebaseStorage = null;

export const isSupported = (): Promise<boolean> => isFirebaseSupported();

export const getToken = (): Promise<string> => messaging
  ? getFirebaseToken(messaging, {vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,})
  : Promise.reject();
export const deleteToken = (): Promise<boolean> => messaging ? deleteFirebaseToken(messaging) : Promise.reject();
export const onNotification = (handle: NextFn<MessagePayload> | Observer<MessagePayload>): Unsubscribe | null => messaging ? onFirebaseMessage(messaging, handle) : null;
export const getFirebaseDb = (): Database | null => firebaseDb;
export const getFirebaseStorage = (): FirebaseStorage | null => storage;
export const initFirebase = (): void => {
  isSupported().then(supported => {
    firebaseDb = getDatabase(firebaseApp);
    storage = getStorage(firebaseApp);
    if (supported) {
      messaging = getMessaging(firebaseApp);
    }
  }).catch(() => {/** Nothing to do */});
};

export const canNotify = async (): Promise<boolean> => {
  let canNotifyUser = false;
  try {
    await navigator.serviceWorker.ready;
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        canNotifyUser = true;
      } else if (Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
        canNotifyUser = permission === 'granted';
      }
    }
  } catch(e) {/** Nothing to do */}

  return canNotifyUser;
};

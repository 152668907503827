import { app } from '@/main';
import { AppActionTypes } from '@/store/modules/app/actions';
import { getAdmob, isIosNativeApp, onTrackingAuthorized, onTrackingRejected } from '@/utils/native-app';
import { loadInterstitialLastSeen } from '@/services/ads';
const getBannerAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_BANNER_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_BANNER_UNIT_ID;
const getInterstitialAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_INTERSTITIAL_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_INTERSTITIAL_UNIT_ID;
const getRewardedAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_REWARDED_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_REWARDED_UNIT_ID;

let consentForm: string = null;

export const isConsentFormObtained = async (): Promise<boolean> => {
  const { AdmobConsentStatus } = await getAdmob();

  return consentForm === AdmobConsentStatus.OBTAINED;
};
export const initNativeAppAds = async (): Promise<boolean> => {
  const { AdMob, AdmobConsentStatus } = await getAdmob();

  try {
    await AdMob.initialize();

    const trackingInfo = await AdMob.trackingAuthorizationStatus();
    if (trackingInfo.status === 'notDetermined') {
      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (authorizationStatus.status === 'authorized') {
      onTrackingAuthorized();
    } else {
      onTrackingRejected();
    }

    const consentInfo = await AdMob.requestConsentInfo();
    if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
      const {status} = await AdMob.showConsentForm();
      consentForm = status;
    } else {
      consentForm = AdmobConsentStatus.OBTAINED;
    }

  } catch (e) {
    // Nothing to do
  }

  app.$store.dispatch(AppActionTypes.SET_ADS_LOADED, true);
  await showBannerNativeApp();
  await initIntertitials();
  await initRewardeds();

  return true;
};

let bannerCreated = false;

export const showBannerNativeApp = async (): Promise<void> => {
  const { AdMob, BannerAdPosition, BannerAdSize, BannerAdPluginEvents } = await getAdmob();
  if (!bannerCreated) {
    const consentFormObtained = await isConsentFormObtained();
    if (consentFormObtained) {
      AdMob.addListener(BannerAdPluginEvents.SizeChanged, size => {
        app.$store.dispatch(AppActionTypes.SET_TOP_MODAL_HEIGHT, size.height);
      });

      AdMob.showBanner({
        adId: getBannerAdUnitId(),
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.TOP_CENTER,
      }).then(() =>{
        bannerCreated = true;
      }).catch(() => {
        bannerCreated = false;
      });
    }
  } else {
    AdMob.resumeBanner();
  }
};

export const hideBannerNativeApp = async (): Promise<void> => {
  const { AdMob } = await getAdmob();
  AdMob.hideBanner().then(() => {
    app.$store.dispatch(AppActionTypes.SET_TOP_MODAL_HEIGHT, 0);
  });
};

export const removeBannerNativeApp = async (): Promise<void> => {
  const { AdMob } = await getAdmob();
  AdMob.removeBanner().then(() => {
    bannerCreated = false;
    app.$store.dispatch(AppActionTypes.SET_TOP_MODAL_HEIGHT, 0);
  });
};

let resolveInterstitialFunction: (value: boolean) => void = null;

const onShowInterstitialResponse = (success: boolean) => {
  if (resolveInterstitialFunction) {
    resolveInterstitialFunction(success);
    resolveInterstitialFunction = null;
  }
};

const initIntertitials = async () => {
  const { AdMob, InterstitialAdPluginEvents } = await getAdmob();
  loadInterstitialLastSeen();

  AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
    onShowInterstitialResponse(true);
  });

  AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, () => {
    onShowInterstitialResponse(false);
  });

  AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, () => {
    onShowInterstitialResponse(false);
  });
};

export const showInterstitialNativeApp = async (): Promise<boolean> => {
  const { AdMob } = await getAdmob();

  await AdMob.prepareInterstitial({adId: getInterstitialAdUnitId()});

  return new Promise((resolve) => {
    onShowInterstitialResponse(false);
    resolveInterstitialFunction = resolve;
    AdMob.showInterstitial().catch(() => onShowInterstitialResponse(false));
  });
};

let resolveRewardedFunction: (value: boolean) => void;

const onShowRewardedResponse = (success: boolean) => {
  if (resolveRewardedFunction) {
    resolveRewardedFunction(success);
    resolveRewardedFunction = null;
  }
};

const initRewardeds = async () => {
  const { AdMob, RewardAdPluginEvents } = await getAdmob();

  AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
    onShowRewardedResponse(true);
  });

  AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () => {
    onShowRewardedResponse(false);
  });

  AdMob.addListener(RewardAdPluginEvents.FailedToShow, () => {
    onShowRewardedResponse(false);
  });
};

export const showRewardedNativeApp = async (): Promise<boolean> => {
  const { AdMob } = await getAdmob();

  await AdMob.prepareRewardVideoAd({adId: getRewardedAdUnitId()});

  return new Promise((resolve) => {
    onShowRewardedResponse(false);
    resolveRewardedFunction = resolve;
    AdMob.showRewardVideoAd().catch(() => onShowRewardedResponse(false));
  });
};


  import Vue from "vue";
  import PwaCurrency from "@/components/common/PwaCurrency.vue";
  import {USER_RANKINGS_PERIOD, USER_RANKINGS_TYPE} from "@/utils/game";
  import {CurrencyType, ordinal} from "@/utils/common";
  import {ROUTES} from "@/router/routes";


  export default Vue.extend({
    name: "pwa-game-users-rankings",
    components: {
      PwaCurrency,
    },
    data() {
      return {
        userRankingData: null as any,
        period: this.userRankingPeriod,
        type: this.userRankingType,
        offset: 3,
        limit: 7,
        hasMoreItems: true,
        podium: [],
        list: [],
        userRankingsType: USER_RANKINGS_TYPE,
      }
    },
    props: {
      gameId: {
        type: Number,
        required: true,
      },
      gameName: {
        type: String,
        required: false,
      },
      userRankingPeriod: {
        type: String,
        default: USER_RANKINGS_PERIOD.WEEKLY,
        required: false,
      },
      userRankingType: {
        type: String,
        default: USER_RANKINGS_TYPE.WINS,
        required: false,
      },
      userRankingDefault: {
        type: Object,
        default: undefined,
        required: false,
      },
      allowChange: {
        type: Boolean,
        default: false,
        required: false,
      }
    },
    mounted(): void {
      if (this.userRankingDefault === undefined) {
        this.restartRankingData();
      } else if (this.userRankingDefault) {
        this.initRankingData(this.userRankingDefault);
      }
    },
    computed: {
      userPosition(): number {
        return this.userRankingData && this.userRankingData.user && this.userRankingData.user.position
          ? this.userRankingData.user.position
          : null;
      },
      isCurrentUserInCurrentList(): boolean {
        return this.userPosition >= this.offset
          && this.userPosition <= (this.offset + this.limit);
      },
      isCurrentUserInPodium(): boolean {
        return this.userPosition >= 1 && this.userPosition <= 3;
      },
      currency(): CurrencyType {
        return this.$store.state.app.config.currency;
      },
    },
    methods: {
      async refreshRankingList() {
        try {
          this.showLoader(true);
          const limit = (this.offset + this.limit > 50) ? (this.limit - ((this.offset + this.limit) - 50)) : this.limit;
          const params = {
            gameId: this.gameId,
            period: this.period,
            type: this.type,
            offset: this.offset,
            limit: limit,
          };
          const {data}: any = await this.$http.page.getUserRanking(params);
          this.list = data.data.list || [];
          this.hasMoreItems = (this.list.length >= this.limit) && (limit == this.limit);
        } catch (e) {
          this.list = [];
        } finally {
          this.showLoader(false);
        }
      },
      async restartRankingData() {
        try {
          this.showLoader(true);
          this.offset = 3;
          const params = {
            gameId: this.gameId,
            period: this.period,
            type: this.type,
            offset: 0,
            limit: 10,
          };
          const {data}: any = await this.$http.page.getUserRanking(params);
          this.initRankingData(data.data);
        } catch (e) {
          this.userRankingData = {};
          this.list = [];
          this.podium = [];
        } finally {
          this.showLoader(false);
        }
      },
      getUserId(userRankingData: Record<string, any> | undefined): string | number {
        const user = userRankingData && userRankingData.user ? userRankingData.user : {};
        return user.id || null;
      },
      getUserName(userRankingData: Record<string, any> | undefined): string | number {
        const user = userRankingData && userRankingData.user ? userRankingData.user : {};
        return user.username || '-';
      },
      getUserAvatar(userRankingData: Record<string, any> | undefined): string {
        const user = userRankingData && userRankingData.user ? userRankingData.user : {};
        return this.getAvatar(user.avatar);
      },
      getUserPoints(userRankingData: Record<string, any> | undefined): string {
        let pointsFormatted = '-';
        const points = userRankingData && userRankingData.points !== null ? userRankingData.points : null;
        if (points !== null) {
          if (this.type === USER_RANKINGS_TYPE.WINS) {
            pointsFormatted = parseInt(points).toString() + ' ' + this.$t("100");
          } else if (this.type === USER_RANKINGS_TYPE.PRIZE) {
            pointsFormatted = this.formatPrice(points, !this.currency.icon, this.currency.decimals, false);
          } else if (this.type === USER_RANKINGS_TYPE.WIN_RATE) {
            pointsFormatted = `${parseInt(points).toString()}%`;
          }
        }

        return pointsFormatted;
      },
      ordinal(num: number) {
        return ordinal(num);
      },
      initRankingData(userRankingData: any) {
        let list = [];
        if (userRankingData && userRankingData.list) {
          list = userRankingData.list;
          delete userRankingData.list;
        }

        this.userRankingData = userRankingData;
        this.podium = list.slice(0, 3);
        this.list = list.slice(3, 10);
        this.hasMoreItems = this.list.length >= this.limit;
      },
      isCurrentUser(userId: number | string) {
        return this.userId == userId;
      },
      onTypeChange(type: string) {
        if (this.type !== type) {
          this.type = type;
          this.restartRankingData();
        }
      },
      onSeeMoreClick() {
        if (this.hasMoreItems) {
          this.offset = this.offset + this.limit;
          this.refreshRankingList();
        }
      },
      onSeeLessClick() {
        if (this.offset > 3) {
          const offset = this.offset - this.limit;
          this.offset = offset < 3 ? 3 : offset;
          this.refreshRankingList();
        }
      },
      onSeeUserPositionClick() {
        if (this.userPosition > 3) {
          const offset = this.userPosition - 5;
          this.offset = offset < 3 ? 3 : offset;
          this.refreshRankingList();
        }
      },
      getPodiumOrder() {
        return [1, 0, 2];
      },
      onUserClick(username: string) {
        if (username && username !== '-') {
          this.routerPush(ROUTES.profile.name, {username});
        }
      },
      getUserNamePath(username: string) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        return (username && username !== '-')
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(username))}`
          : '#';
      },
    },
    watch: {
      userRankingDefault() {
          this.initRankingData(this.userRankingDefault);
      },
    },
  });

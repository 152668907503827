import { RawLocation } from "vue-router/types/router";
import { ROUTES } from "@/router/routes";

export type BreadcrumbItemType = { text?: string, labelId?: string, to?: RawLocation };

export const BreadcrumbItems = {
  lobby: {labelId: '6', to: {name: ROUTES.lobby.name}},
  competitions: {labelId: '8', to: {name: ROUTES.competitions.name}},
  competitionsHistory: {labelId: '108', to: {name: ROUTES.competitionsHistory.name}},
  aboutUs: {labelId: '1', to: {name: ROUTES.aboutUs.name}},
  privacy: {labelId: '15', to: {name: ROUTES.privacy.name}},
  help: {labelId: '2', to: {name: ROUTES.help.name}},
  cookies: {labelId: '14', to: {name: ROUTES.cookies.name}},
  messages: {labelId: '9', to: {name: ROUTES.messages.name}},
  terms: {labelId: '13', to: {name: ROUTES.terms.name}},
  wallet: {labelId: '7', to: {name: ROUTES.wallet.name}},
  verifyIdentity: {labelId: '387', to: {name: ROUTES.verifyIdentity.name}},
  inviteFriend: {labelId: '311', to: {name: ROUTES.inviteFriend.name}},
  account: {labelId: '111', to: {name: ROUTES.account.name}},
  profile: {labelId: '439', to: {name: ROUTES.profile.name}},
  blog: {labelId: '625', to: {name: ROUTES.blogHome.name}},
  challenges: {labelId: '684', to: {name: ROUTES.challenges.name}},
  roulette: {labelId: '719', to: {name: ROUTES.roulette.name}},
  lastPrizesDistributed: {labelId: '720', to: {name: ROUTES.lastPrizesDistributed.name}},
};


  import Vue from 'vue';
  import PwaCarousel from "@/components/common/PwaCarousel.vue";
  import PwaAllGames from "@/components/game/PwaAllGames.vue";
  import PwaAllMinigames from "@/components/game/PwaAllMinigames.vue";
  import PwaDepositBanner from "@/components/deposit/PwaDepositBanner.vue";
  import PwaCompetitionCard from "@/components/competitions/PwaCompetitionCard.vue";
  import PwaMultiplayerCard from '@/components/competitions/PwaMultiplayerCard.vue';
  import PwaNoCompetitions from "@/components/competitions/PwaNoCompetitions.vue";
  import PwaUserActive from "@/components/user/PwaUserActive.vue";
  import {ROUTES} from "@/router/routes";
  import { MODAL_IDS } from "@/utils/modal";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import pageMixin from "@/mixins/page";
  import PwaGameUsersRankings from '@/components/game/PwaGameUsersRankings.vue';
  import { COMPETITION_TYPE_IDS } from '@/utils/competition';
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import {isIOS, isPrerender, onBeforeInstallPrompt} from "@/utils/common";
  import {MODAL_NAMES} from "@/store/modules/modalsSeen/actions";
  import { MetaInfo } from 'vue-meta';

  export default Vue.extend({
    name: "Lobby",
    mixins: [pageMixin],
    components: {
      PwaCarousel,
      PwaAllGames,
      PwaAllMinigames,
      PwaDepositBanner,
      PwaCompetitionCard,
      PwaMultiplayerCard,
      PwaNoCompetitions,
      PwaGameUsersRankings,
      PwaUserActive,
      PwaIcon,
      PwaCrossSellingBanner: () => import('@/components/PwaCrossSellingBanner.vue'),
      PwaDownloadAppBanner: () => import('@/components/PwaDownloadAppBanner.vue'),
    },
    data() {
      return {
        banners: this.getPageInitialData('banners', []) as any[],
        bannerData: this.getPageInitialData('bannerData', {}) as any,
        games: this.getPageInitialData('games', []) as any[],
        minigames: this.getPageInitialData('minigames', []) as any[],
        wagers: [],
        hasMoreWagers: false,
        wagersOffset: 0,
        wagersLimit: 4,
        multiplayers: [],
        hasMoreMultiplayers: false,
        multiplayersOffset: 0,
        multiplayersLimit: 4,
        users: [],
        hasMoreUsers: false,
        usersOffset: 0,
        usersLimit: 4,
        gameCategories: this.getPageInitialData('gameCategories', []) as any[],
        rankings: null,
        bannerNumber: null,
        breadcrumb: [{labelId: BreadcrumbItems.lobby.labelId}] as BreadcrumbItemType[],
      }
    },
    mounted(): void {
      if(this.isMobile || this.isTablet){
        this.wagersLimit = 3;
        this.multiplayersLimit = 3;
      }
      this.getLobbyPage();
      this.bannerNumber = this.getRandomBannerNumber();
      if (!isPrerender) {
        this.removePageInitialData();
        this.showLoginModals();
      }
    },
    computed: {
      userGames(): Array<any> {
        return this.$store.state.user.games;
      },
      metaData(): MetaInfo {
        return {
          title: this.$t('711'),
          meta: [
            {name: 'description', content: this.$t('712')},
            {name: 'robots', content: 'index'}
          ],
          link: this.getMetaDataPathLinks(ROUTES.lobby.path),
        };
      },
    },
    methods: {
      async getLobbyPage(): Promise<any> {
        await this.showLoader(true);
        try {
          let {data} = await this.$http.page.getLobbyPage({});
          this.banners = data.data.banners || [];
          this.bannerData = data.data.banner_data || {};
          if (!isPrerender) {
            await Promise.all([
              this.getWagersFiltered(false),
              this.getMultiplayersFiltered(false),
            ]);
          }
          this.users = data.data.users;
          this.hasMoreUsers = this.users.length >= this.usersLimit;
          this.games = data.data.games;
          this.minigames = data.data.minigames;
          this.gameCategories = data.data.game_categories;
          this.rankings = data.data.rankings;

          if (isPrerender) {
            this.savePageInitialData({
              banners: this.banners,
              bannerData: this.bannerData,
              games: this.games,
              gameCategories: this.gameCategories,
              minigames: this.minigames
            })
          }
        } catch (e) {
          // Show error
        } finally {
          await this.showLoader(false);
        }
      },
      async getWagersFiltered(paginate: boolean): Promise<any> {
        this.showLoader(true);
        this.wagersOffset = paginate ? this.wagersOffset : 0;
        let params: any = {
          type_id: COMPETITION_TYPE_IDS.WAGER,
          limit: this.wagersLimit,
          offset: this.wagersOffset,
        };

        try {
          const {data} = await this.$http.competition.postCompetitionsFilter(params);
          const wagers: Record<string, any>[] = data.data;
          if (this.wagers && paginate) {
            wagers.forEach(wager => this.wagers.push(wager));
          } else {
            this.wagers = wagers;
          }
          this.hasMoreWagers = (wagers.length >= this.wagersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onSeeMoreWagersClick(): void {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreWagers) {
            this.wagersOffset = this.wagersOffset + this.wagersLimit;
            this.getWagersFiltered(true);
          }
        }
      },
      async getMultiplayersFiltered(paginate = false, showLoader = true) {
        if (showLoader) {
          this.showLoader(true);
        }
        this.multiplayersOffset = paginate ? this.multiplayersOffset : 0;
        const params: any = {
          type_id: COMPETITION_TYPE_IDS.MULTIPLAYER,
          limit: this.multiplayersLimit,
          offset: this.multiplayersOffset,
        };

        try {
          const {data} = await this.$http.competition.postCompetitionsFilter(params);
          const competitionsMultiplayer: Record<string, any>[] = data.data;
          if (this.multiplayers.length && paginate) {
            competitionsMultiplayer.forEach(multiplayer => this.multiplayers.push(multiplayer));
          } else {
            this.multiplayers = competitionsMultiplayer;
          }
          this.hasMoreMultiplayers = (competitionsMultiplayer.length >= this.multiplayersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          if (showLoader) {
            this.showLoader(false);
          }
        }
      },
      onSeeMoreMultiplayersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreMultiplayers) {
            this.multiplayersOffset = this.multiplayersOffset + this.multiplayersLimit;
            this.getMultiplayersFiltered(true);
          }
        }
      },
      onGameClick(game: any): void {
        this.$router.push({name: ROUTES.arena.name, params: {lang: this.routeLang, gameAlias: game.alias}});
      },
      onAddNewGameClick(): void {
        this.showModalById(MODAL_IDS.ADD_GAME);
      },
      getRandomBannerNumber(): number {
        return Math.floor(Math.random() * (3 - 1 + 1) + 1);
      },
      async onButtonBannerClick(): Promise<any> {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if(this.bannerData.url_path){
            await this.$router.push((this.routeLang ? ('/' + this.routeLang) : '') + this.bannerData.url_path);
          }else{
            if (this.isDesktop) {
              this.showModalById(MODAL_IDS.DEPOSIT);
            } else {
              await this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}});
            }
          }
        }
      },
      onSeeMoreUsersClick(): void {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreUsers) {
            this.usersOffset = this.usersOffset + this.usersLimit;
            this.getMoreUsersToChallenge();
          }
        }
      },
      async getMoreUsersToChallenge(): Promise<any> {
        this.showLoader(true);
        let params: any = {
          limit: this.usersLimit,
          offset: this.usersOffset,
        };

        try {
          const {data} = await this.$http.user.getUsersToChallange(params);
          const users: Record<string, any>[] = data.data;
          if (this.users) {
            users.forEach(user => this.users.push(user));
          }
          this.hasMoreUsers = (users.length >= this.usersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      getButtonText(): string {
        let text = '';

        if(this.bannerData.button_text) {
          text = this.bannerData.button_text;
        }else{
          text = this.$t('171');
        }

        return text;
      },
      getButtonClass(): string {
        let btnClass = '';

        if(this.bannerData.button_class) {
          btnClass = this.bannerData.button_class;
        }else{
          btnClass = 'cta-principal';
        }

        return btnClass;
      },
      async showInstallPwaModal(): Promise<boolean> {
        let modalShown = false;
        if (this.isMobile && !this.$store.state.app.isPwaInstalled && !this.isNativeApp) {
          if (this.canShowModal(MODAL_NAMES.INSTALL_PWA)) {
            if (isIOS) {
              modalShown = true;
              this.showModalById(MODAL_IDS.INSTALL_PWA);
            } else {
              try {
                await onBeforeInstallPrompt();
                modalShown = true;
                this.showModalById(MODAL_IDS.INSTALL_PWA);
              } catch (e) {
                // Nothing to do
              }
            }
          }
        }

        return modalShown;
      },
      showLoginModals(): void {
        if(this.isLoggedIn){
          setTimeout(async () => {
            let shown = false;
            try {
              shown = await this.showInstallPwaModal();
            } catch (e) {
              // Nothing to do
            }

            if (this.allowDeposit && !this.$store.state.user.wallet.has_deposit && this.canShowModal(MODAL_NAMES.DEPOSIT_REMINDER)) {
              if (!shown) {
                this.showModalById(MODAL_IDS.DEPOSIT_REMINDER, null);
              }
            }
          }, 3000);
        }
      },
    },
  });

import { isIOS } from '@/utils/common';
import { isAndroidNativeApp, isIosNativeApp, isNativeApp, openNativeAppLink, openUrl } from '@/utils/native-app';

export type OpenAppLink = {
  deepLink?: string,
  packageName?: string,
  iosUrl?: string,
  androidUrl?: string
};

export const openApp = (data: OpenAppLink): void => {
  const { androidUrl, deepLink, iosUrl, packageName }  = data;
  launchApp(deepLink, packageName)
    .catch(() => {
      const url = isIosNativeApp || isIOS ? iosUrl : androidUrl;
      openLink(url);
    });
};

/**
 * Launches the app via a specified URI or Package Name
 *
 * @param deepLink App deepLink
 * @param packageName App packageName
 * @return Returns a promise that resolves the launched app
 */
const launchApp = (deepLink: string, packageName: string): Promise<any> => {
  const url = isIosNativeApp || isIOS ? deepLink : packageName;
  return new Promise((resolve, reject) => {
    if (url && isNativeApp) {
      openUrl(url)
        .then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        })
        .catch(reject);
    } else {
      reject();
    }
  });
};

const openDeepLink = (deepLink: string, url: string): void => {
  if (deepLink && isNativeApp) {
    openUrl(deepLink)
      .then(success => {
        if (!success) {
          openLink(url);
        }
      })
      .catch(() => openLink(url));
  } else {
    openLink(url);
  }
};

export const openInstagramProfile = (username: string): void => {
  const value = username.charAt(0) === '@' ? username.substring(1) : username;
  const deepLink = `instagram://user?username=${value}`;
  const url = `https://www.instagram.com/${value}`;
  openDeepLink(deepLink, url);
};

export const openYoutubeChannel = (channel: string): void => {
  const deepLink = `youtube://channel?id=${channel}`;
  const url = `https://www.youtube.com/channel/${channel}`;
  openDeepLink(deepLink, url);
};

export const openFacebookProfile = (username: string): void => {
  const deepLink = `fb://page/${username}`;
  const url = `https://facebook.com/${username}`;
  openDeepLink(deepLink, url);
};

export const openTwitterProfile = (username: string): void => {
  const deepLink = `twitter://${username}`;
  const url = `https://twitter.com/${username}`;
  openDeepLink(deepLink, url);
};

export const openDiscordPage = (userId: string): void => {
  const deepLink = `com.hammerandchisel.discord://${userId}`;
  const url = `https://discord.com/users/${userId}`;
  openDeepLink(deepLink, url);
};

export const openTwitchChannel = (username: string): void => {
  const deepLink = `twitch://stream/${username}`;
  const url = `https://www.twitch.tv/${username}`;
  openDeepLink(deepLink, url);
};

export const openLink = async (url: string): Promise<void> => {
  if (isAndroidNativeApp) {
    openNativeAppLink(url);
  } else {
    window.open(url, '_blank');
  }
};

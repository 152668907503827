export const getUrlDomain = (value: string): string => {
  const matches = value.match(/^(?:https?:\/\/)?(?:www\.)?([^/:?#]+)(?:[/:?#]|$)/i);

  return matches && matches[1] ? matches[1] : '';
};

const urlMatch = /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;

export const getAllUrls = (value: string): RegExpMatchArray | null => value.match(urlMatch);

export const containsUrl = (value: string): boolean => {
  const urls = getAllUrls(value);

  return urls && urls.length > 0;
};


export const isRelativeUrl = (url: string): boolean => url.charAt(0) === '/';

import api, { ApiCallReturn } from "../api";

export const getHomePage = (): ApiCallReturn => api.get('/web/pages/home');
export const getLobbyPage = (params: Record<string, unknown>): ApiCallReturn => api.get('/web/pages/lobby', {params});
export const getArenaPage = (gameAlias: string, params: Record<string, unknown>): ApiCallReturn => api.get(`/web/pages/arena/${gameAlias}`, {params});
export const getMinigamePage = (gameAlias: string, params: Record<string, unknown>): ApiCallReturn => api.get(`/web/pages/minigame/${gameAlias}`, {params});
export const getUserRanking = (params: Record<string, unknown>): ApiCallReturn => api.get('/pages/user-ranking', {params});
export const getCompetitionPage = (competitionId: number|string, params: Record<string, string|number> = {}): ApiCallReturn => api.get(`/web/pages/competition/${competitionId}/results`, {params});
export const getRankedPage = (competitionId: number, params: Record<string, string|number> = {}): ApiCallReturn => api.get(`/web/pages/ranked/${competitionId}`, {params});
export const getMultiplayerPage = (competitionId: number|string, params: Record<string, string|number> = {}): ApiCallReturn => api.get(`/web/pages/multiplayer/${competitionId}`, {params});

export const getCompetitionHistoryPage = (): ApiCallReturn => api.get('/web/pages/competitions/history');
export const getUserProfilePage = (username: string): ApiCallReturn => api.get(`/web/pages/user-profile/${encodeURIComponent(username)}`);
export const getUserProfileGamePage = (username: string, gameId: number|string): ApiCallReturn => api.get(`/web/pages/user-profile-game/${gameId}/${encodeURIComponent(username)}`);
export const getWalletPage = (params: Record<string, unknown>): ApiCallReturn => api.get(`/web/pages/wallet`, {params});
export const getWalletDepositPage = (): ApiCallReturn => api.get('/web/pages/wallet/deposit');
export const getWalletWithdrawPage = (params: Record<string, unknown> = {}): ApiCallReturn => api.get('/web/pages/wallet/withdraw', {params});
export const getCompetitionsPage = (): ApiCallReturn => api.get('/web/pages/competitions');
export const getHelpPage = (): ApiCallReturn => api.get('/web/pages/help');
export const getUserFollowersPage = (username: string): ApiCallReturn => api.get(`/web/pages/followers/${encodeURIComponent(username)}`);
export const getAccountPage = (): ApiCallReturn => api.get(`/web/pages/user-account`);
export const getAddGamePage = (): ApiCallReturn => api.get(`/web/pages/add-game`);
export const getCompetitionsRoundResultsPage = (competitionId: number, userId: number, round: number, params: Record<string, string|number> = {}): ApiCallReturn => api.get(`/pages/competition/${competitionId}/results/${userId}/round/${round}`, {params});
export const getVerifyIdentityPage = (): ApiCallReturn => api.get('/web/pages/verify-identity');
export const getPromoPage = (promoId: string): ApiCallReturn => api.get(`/web/pages/promo/${promoId}`);
export const getLandingWelcomePage = (): ApiCallReturn => api.get('/web/pages/landing-welcome');
export const getRoulettePage = (): ApiCallReturn => api.get('/pages/roulette');
export const getChallengesPage = (): ApiCallReturn => api.get('/pages/challenges');
export const getLastPrizesDistributedPage = (offset: number, limit: number): ApiCallReturn => api.get('/pages/prizes-distributed', { params: {offset, limit} });

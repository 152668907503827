
  import Vue, {PropType} from "vue";
  import PwaCurrency from "@/components/common/PwaCurrency.vue";

  const currencyTypes = {
    auto: 'auto',
    icon: 'icon',
    symbol: 'symbol',
    none: 'none',
  };

  export default Vue.extend({
    name: "pwa-price",
    props: {
      amount: {
        type: [Number, String],
        required: true,
      },
      currencyType: {
        type: String as PropType<"auto" | "icon" | "symbol" | "none">,
        default: 'auto',
        required: false,
      },
      iconHeight: {
        type: String,
        default: '1em',
        required: false,
      },
      numDecimals: {
        type: Number,
        default: 2,
        required: false,
      },
      onlyNumbers: {
        type: Boolean,
        default: false,
        required: false,
      },
      removeDecimalsIfPossible: {
        type: Boolean,
        default: false,
        required: false,
      },
      amountConverted: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    components: {
      PwaCurrency,
    },
    computed: {
      showCurrency(): boolean {
        return this.currencyType !== currencyTypes.none;
      },
      decimals(): number {
        let number = this.$store.state.app.config.currency.decimals;
        if(this.numDecimals){
          number = Math.min(number, this.numDecimals);
        }
        return number;
      },
      symbolPosRight(): boolean {
        return this.$store.state.app.config.currency.symbolPosRight;
      },
      showLeftCurrency(): boolean {
        return this.showCurrency && !this.symbolPosRight;
      },
      showRightCurrency(): boolean {
        return this.showCurrency && this.symbolPosRight;
      },
    },
  });

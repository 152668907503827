import Vue from 'vue';
import {
  initPushNotifications,
  loadAllNativeLibraries,
  registerPushNotifications,
  unregisterPushNotifications,
  initNativeAppAds, isLegacyNativeApp,
  FROM_NATIVE_APP_EVENTS, getAppVersion,
} from '@/utils/native-app';
import { removeBannerNativeApp, showBannerNativeApp } from '@/utils/native-app/native-app-ads';
import { AuthActionTypes } from '@/store/modules/auth/actions';
import { CONSENT_UPDATE_EVENT_NAME, getAllGrantedCookieConsentValues } from '@/utils/cookie-consent';
import { setAppPlatformHeader, setAppVersionHeader } from '@/services/api/api';
import { EVENTS } from '@/utils/events';

export default Vue.extend({
  beforeMount(): void {
    if (isLegacyNativeApp()) {
      this.initOnLegacyNativeAppEvent();
      this.initFromLegacyNativeAppEvents();
      setAppPlatformHeader('app');
    } else {
      this.initNativeAppLibraries();
    }
  },
  methods: {
    initOnLegacyNativeAppEvent() {
      window.onNativeAppEvent = (type: string, data: string = null) => {
        let eventData = null;
        if (data) {
          try {
            eventData = JSON.parse(data);
          } catch (e) {
            // Nothing to do
          }
        }

        this.$root.$emit(type, eventData);
      };
    },
    initFromLegacyNativeAppEvents() {
      this.$root.$on(FROM_NATIVE_APP_EVENTS.SET_NOTIFICATION_TOKEN, ({ token}: {token: string}) => {
        this.$store.dispatch(AuthActionTypes.UPDATE_FIREBASE_TOKEN, {userId: this.userId, token})
      });
      this.$root.$on(FROM_NATIVE_APP_EVENTS.NOTIFICATION_RECEIVED, ({title, body}: {title: string, body: string}) => {
        this.showToast(title, body);
      });
      this.$root.$on(FROM_NATIVE_APP_EVENTS.SOCIAL_LOGIN_SUCCESS, (data: {platform: string, data: string}) => {
        this.$root.$emit(EVENTS.SOCIAL_LOGIN, data);
      });
      this.$root.$on(FROM_NATIVE_APP_EVENTS.CONSENT_COOKIES, () => {
        const values = getAllGrantedCookieConsentValues();
        this.pushCookieConsentDataLayer('consent', 'update', values);
        this.sendAnalyticsEvent(CONSENT_UPDATE_EVENT_NAME, values);

        this.$store.dispatch(AuthActionTypes.ACCEPT_COOKIES);
      });
    },
    async initNativeAppLibraries() {
      await loadAllNativeLibraries();

      getAppVersion().then(version => {
        setAppVersionHeader(version);
      }).catch(() => {/** Notjing to do */});

      initPushNotifications();

      if (this.hasAds) {
        initNativeAppAds();
      }

      if (this.isLoggedIn) {
        registerPushNotifications();
      }
    },
  },
  watch: {
    hasAds() {
      if (this.hasAds) {
        showBannerNativeApp();
      } else {
        removeBannerNativeApp();
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn) {
        if (!isLegacyNativeApp()) {
          registerPushNotifications();
        }
      } else {
        if (!isLegacyNativeApp()) {
          unregisterPushNotifications();
        }
      }
    },
  }
});


  import Vue from 'vue';
  import { MODAL_IDS } from "@/utils/modal";
  import { ROUTES } from '@/router/routes';

  export default Vue.extend({
    name: 'pwa-inscription-no-money',
    props: {
      create: {
        type: Boolean,
        required: true,
      },
      costInscription: {
        type: Number,
        required: true,
      }
    },
    computed: {
      requiredAmount(): string {
        return this.formatPrice((this.costInscription - this.walletQuantity), true);
      }
    },
    methods: {
      onDepositClick() {
        this.hideModalById('modal-no-money');
        if (this.isDesktop) {
          this.showModalById(MODAL_IDS.DEPOSIT);
        } else {
          this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}});
        }
      },
      onCreateMatchClick() {
        this.hideModalById('modal-no-money');
        this.createMatch();
      },
      onDoTaskClick() {
        this.hideModalById('modal-no-money');
        this.$router.push({name: ROUTES.challenges.name, params: {lang: this.routeLang}});
      },
      onCancelClick() {
        this.hideModalById('modal-no-money');
      }
    }
  });

import { GAMES_ALIAS } from '@/utils/game';
import { MINIGAMES_ALIAS } from '@/utils/minigames';

export const ARENA_PAGE_TITLE_LABEL = {};

export const ARENA_PAGE_DESCRIPTION_LABEL = {};

export const ARENA_PAGE_SUBTITLE_LABEL = {
  [GAMES_ALIAS.FIFA_23]: '465',
  [GAMES_ALIAS.EA_SPORTS_FC_24]: '522',
  [GAMES_ALIAS.EA_SPORTS_FC_25]: '725',
  [GAMES_ALIAS.EIGHT_BALL_POOL]:'466',
  [GAMES_ALIAS.COD_MOBILE]:'467',
  [GAMES_ALIAS.COD_MODERN_WARFARE_3]:'470',
  [GAMES_ALIAS.COD_WARZONE]:'471',
  [GAMES_ALIAS.HEAD_BALL_2]:'489',
  [GAMES_ALIAS.NBA_2K24]:'514',
  [GAMES_ALIAS.BOWLING_KING]:'525',
  [GAMES_ALIAS.SOCCER_STARS]:'537',
  [GAMES_ALIAS.PING_PONG_FURY]:'581',
  [GAMES_ALIAS.EFOOTBALL_2024]:'582',
  [GAMES_ALIAS.F1_23]:'605',
  [GAMES_ALIAS.EA_FC_24_MOBILE]:'613',
  [GAMES_ALIAS.UFC_5]:'614',
  [GAMES_ALIAS.MINIGOLF_PLATO]:'617',
  [GAMES_ALIAS.FOOTBALL_STRIKE]:'628',
  [GAMES_ALIAS.WARZONE_MOBILE]:'629',
  [GAMES_ALIAS.FREE_FIRE]:'633',
  [GAMES_ALIAS.STOP_2]:'636',
  [GAMES_ALIAS.RUMBLE_STARS]:'656',
  [GAMES_ALIAS.MINI_TENNIS]:'679',
};

export const MINIGAME_PAGE_SUBTITLE_LABEL = {
  [MINIGAMES_ALIAS.WETRIS]:'564',
  [MINIGAMES_ALIAS.FLAPPY_GOAL]:'622',
  [MINIGAMES_ALIAS.TAP_TAP]:'643',
  [MINIGAMES_ALIAS.BITE_MAN]:'707',
  [MINIGAMES_ALIAS.SERPENT]:'709',
  [MINIGAMES_ALIAS.WELIX_JUMP]:'710',
  [MINIGAMES_ALIAS.CHRONO]:'727',
};


  import Vue from 'vue';
  import PwaGameHeader from "@/components/game/PwaGameHeader.vue";
  import PwaCompetitionCard from "@/components/competitions/PwaCompetitionCard.vue";
  import PwaDepositBanner from "@/components/deposit/PwaDepositBanner.vue";
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaNoCompetitions from "@/components/competitions/PwaNoCompetitions.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";
  import PwaUserActive from "@/components/user/PwaUserActive.vue";
  import PwaAllMinigames from '@/components/game/PwaAllMinigames.vue';
  import PwaGameUsersRankings from '@/components/game/PwaGameUsersRankings.vue';
  import {COMPETITION_TYPE_IDS} from "@/utils/competition";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import pageMixin from "@/mixins/page";
  import {EVENTS} from "@/utils/events";
  import {MetaInfo} from "vue-meta";
  import {ROUTES} from "@/router/routes";
  import {ARENA_PAGE_DESCRIPTION_LABEL, ARENA_PAGE_SUBTITLE_LABEL, ARENA_PAGE_TITLE_LABEL} from "@/utils/seo";
  import {isPrerender} from "@/utils/common";

  const TABS = {
    MATCHES: 0,
    GAMECHAT: 1,
  };

  export default Vue.extend({
    name: "Arena",
    mixins: [pageMixin],
    components: {
      PwaGameHeader,
      PwaCompetitionCard,
      PwaDepositBanner,
      PwaSlider,
      PwaIcon,
      PwaNoCompetitions,
      PwaUserLevel,
      PwaUserActive,
      PwaAllMinigames,
      PwaGameUsersRankings,
      PwaChatContainer: () => import("@/components/chat/PwaChatContainer.vue"),
      PwaGamesSlider: () => import("@/components/game/PwaGamesSlider.vue"),
      PwaCrossSellingBanner: () => import('@/components/PwaCrossSellingBanner.vue'),
    },
    data() {
      return {
        wagers: [],
        lastWagers: [],
        lastWagersFiltered: [],
        lastWagersLimit: 4,
        lastWagersOffset: 0,
        hasMoreLastWagers: false,
        users: [],
        chatData: null,
        gameData: this.getPageInitialData('gameData', {}) as any,
        rankings: null,
        //Filtros
        favouritePlatformName: null,
        platformList: [],
        selectedPlatform: {} as any,
        selectedPlatformName: '',
        regionList: [],
        selectedRegion: {} as any,
        selectedRegionName: '',
        //Paginacion
        hasMoreCompetitions: false,
        wagersOffset: 0,
        wagersLimit: 8,
        hasMoreUsers: false,
        usersOffset: 0,
        usersLimit: 4,
        loadMobileChat: false,
        userLevels: [],
        tabs: TABS,
        selectedTab: TABS.MATCHES,
        games: [],
        minigames: [],
        seeGameLevelsInfo: false,
        bannerData: {} as any,
      }
    },
    mounted(): void {
      this.lastWagersLimit = this.isTablet ? 3 : 4;
      if(this.isDesktop){
        this.wagersLimit = 8;
      }else if(this.isTablet){
        this.wagersLimit = 3;
      }
      this.getArenaPage();
      this.$root.$on(EVENTS.RELOAD_ARENA_PAGE, () => this.reloadPage());
      if (!isPrerender) {
        this.removePageInitialData();
      }
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_ARENA_PAGE);
    },
    methods: {
      async getArenaPage() {
        try {
          this.showLoader(true);
          let {data} = await this.$http.page.getArenaPage(this.gameAlias, {limit_rankings: 10, limit_users: this.usersLimit});
          this.chatData = data.data.chat_data;
          const gameData = data.data.game_data;
          this.gameData = gameData;
          if (isPrerender) {
            this.savePageInitialData({gameData})
          }
          this.userLevels = data.data.user_levels || [];
          this.favouritePlatformName = data.data.favourite_platform_name || null;
          this.getUniquePlatforms(this.gameData);
          this.initPlatformAndRegions();
          await this.getCompetitionsFiltered(false);
          this.rankings = data.data.rankings;
          this.users = data.data.users;
          this.hasMoreUsers = this.users.length >= this.usersLimit;
          this.games = data.data.games || [];
          this.minigames = data.data.minigames || [];
          this.bannerData = data.data.banner_data || {};
          this.lastWagers = data.data.last_wagers || {};
          this.lastWagersFiltered = this.lastWagers.slice(0, this.lastWagersLimit);
          this.hasMoreLastWagers = this.lastWagers.length > this.lastWagersLimit;
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      getUniquePlatforms(game: any) {
        let platformNames = [];
        this.platformList = [];
        for (let i in game.platforms) {
          if (platformNames.indexOf(game.platforms[i].platform_name) === -1) {
            platformNames.push(game.platforms[i].platform_name);
            this.platformList.push({
              platform_id: game.platforms[i].platform_id,
              name: game.platforms[i].platform_name,
              icon: game.platforms[i].icon_platform,
              game_id: game.platforms[i].game_id
            });
          }
        }
      },
      initPlatformAndRegions() {
        if(this.favouritePlatformName){
          for (let i in this.platformList) {
            let currentPlatform = this.platformList[i];
            if (this.favouritePlatformName == currentPlatform.name) {
              this.selectedPlatform = currentPlatform;
              break
            }
          }
        }else{
          this.selectedPlatform = this.platformList[0];
        }
        this.selectedPlatformName = this.selectedPlatform.name;
        this.setRegionsByPlatform(this.selectedPlatform);
        if (this.regionList.length === 1) {
          this.selectedRegion = this.regionList[0];
          this.selectedRegionName = this.selectedRegion.region_name;
        }
      },
      setRegionsByPlatform(platform: any) {
        let regions = [];
        for (let i in this.gameData.platforms) {
          let currentPlatform = this.gameData.platforms[i];
          if (platform.name == currentPlatform.platform_name) {
            regions.push(currentPlatform);
          }
        }
        this.regionList = regions;
      },
      onSeeMoreClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreCompetitions) {
            this.wagersOffset = this.wagersOffset + this.wagersLimit;
            this.getCompetitionsFiltered(true);
          }
        }
      },
      onSelectPlatform(platform: any) {
        this.selectedPlatform = platform;
        this.selectedRegion = {};
        this.selectedRegionName = '';
        this.selectedPlatformName = this.selectedPlatform.name;
        this.setRegionsByPlatform(platform);
        this.getCompetitionsFiltered(false);
      },
      onSelectRegion(region: any) {
        this.selectedRegion = region;
        if (region) {
          this.selectedRegionName = region.region_name;
        } else {
          this.selectedRegionName = region;
        }
        this.getCompetitionsFiltered(false);
      },
      async getCompetitionsFiltered(paginate: boolean) {
        this.showLoader(true);
        this.wagersOffset = paginate ? this.wagersOffset : 0;
        let params: any = {
          related_game_id: this.gameData.related_game_id,
          type_id: COMPETITION_TYPE_IDS.WAGER,
          platform_id: this.selectedPlatform.platform_id,
          limit: this.wagersLimit,
          offset: this.wagersOffset,
        };

        if (this.selectedRegion) {
          params.game_id = this.selectedRegion.game_id
        }

        try {
          const {data} = await this.$http.competition.postCompetitionsFilter(params);
          const wagers: Record<string, any>[] = data.data;
          if (this.wagers && paginate) {
            wagers.forEach(wager => this.wagers.push(wager));
          } else {
            this.wagers = wagers;
          }
          this.hasMoreCompetitions = (wagers.length >= this.wagersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onTabChange(tabSelected: number) {
        if (tabSelected === TABS.GAMECHAT && !this.loadMobileChat) {
          this.loadMobileChat = true;
        }
      },
      reloadPage() {
        this.selectedPlatform = {};
        this.selectedPlatformName = '';
        this.selectedRegion = {};
        this.selectedRegionName = '';
        //Paginacion
        this.wagersOffset = 0;
        this.usersOffset = 0;
        this.getArenaPage();
      },
      onSeeMoreUsersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreUsers) {
            this.usersOffset = this.usersOffset + this.usersLimit;
            this.getMoreUsersToChallenge();
          }
        }
      },
      async getMoreUsersToChallenge() {
        this.showLoader(true);
        let params: any = {
          limit: this.usersLimit,
          offset: this.usersOffset,
          game_id: this.gameData.id
        };

        try {
          const {data} = await this.$http.user.getUsersToChallange(params);
          const users: Record<string, any>[] = data.data;
          if (this.users) {
            users.forEach(user => this.users.push(user));
          }
          this.hasMoreUsers = (users.length >= this.usersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onSeeGameLevelsInfoClick() {
        this.seeGameLevelsInfo = !this.seeGameLevelsInfo;
      },
      onSeeMoreLastWagersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreLastWagers) {
            this.lastWagersOffset += this.lastWagersLimit;
            const lastWagersBlock = this.lastWagers.slice(this.lastWagersOffset, (this.lastWagersOffset + this.lastWagersLimit));
            lastWagersBlock.forEach(wager => this.lastWagersFiltered.push(wager));
            this.hasMoreLastWagers = this.lastWagers.length > this.lastWagersFiltered.length;
          }
        }
      }
    },
    computed: {
      userGames(): Array<any> {
        return this.$store.state.user.games;
      },
      gameAlias(): string {
        return this.$route.params.gameAlias || '';
      },
      gameId(): number {
        return this.gameData.id || 0;
      },
      hasGameConfigured() {
        let isGameConfigured = false;
        if (this.gameData.id) {
          if (this.userGames.find((game: any) => this.gameData.id === game.game_id && game.validated === 1)) {
            isGameConfigured = true;
          }
        }

        return isGameConfigured;
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {text: this.gameData.name || '-'},
        ];
      },
      title(): string {
        const label = (ARENA_PAGE_TITLE_LABEL as any)[this.gameAlias] || '';
        return label ? this.$t(label) : this.$t('463', {gameName: this.gameData.name});
      },
      description(): string {
        const label = (ARENA_PAGE_DESCRIPTION_LABEL as any)[this.gameAlias] || '';
        return label ? this.$t(label) : this.$t('464', {gameName: this.gameData.name});
      },
      subtitleText(): string {
        const label = (ARENA_PAGE_SUBTITLE_LABEL as any)[this.gameAlias] || '';
        return label ? this.$t(label, {appName: this.appName}) : this.$t('426', {gameName: this.gameData.name, appName: this.appName});
      },
      metaData(): MetaInfo {
        return {
          title: this.title,
          meta: [
            {name: 'description', content: this.description},
            {name: 'robots', content: 'index'}
          ],
          link: this.getMetaDataPathLinks(ROUTES.arena.path.replace(':gameAlias', this.gameAlias)),
        };
      },
    },
    watch: {
      gameAlias() {
        this.reloadPage();
      },
    }
  });

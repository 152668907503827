module.exports = {
  home: {path: '', prerender: true},
  aboutUs: {path: 'about-us', prerender: true},
  help: {path: 'help', prerender: true},
  support: {path: 'support'},
  lobby: {path: 'lobby', prerender: true},
  arena: {path: 'arena/:gameAlias', prerender: true},
  minigame: {path: 'minigame/:gameAlias', prerender: true},
  wallet: {path: 'wallet'},
  walletSuccess: {path: 'wallet/success'},
  deposit: {path: 'wallet/deposit'},
  withdraw: {path: 'wallet/withdraw'},
  competition: {path: 'competition/:competitionId'},
  ranked: {path: 'ranking/:competitionId'},
  multiplayer: {path: 'multiplayer/:competitionId'},
  competitionChat: {path: 'competition/:competitionId/chat/:matchId'},
  competitionMatch: {path: 'competition/:competitionId/match/:matchId'},
  competitions: {path: 'competitions'},
  competitionsHistory: {path: 'competitions/history'},
  create: {path: 'create'},
  practiceGame: {path: 'practice/game'},
  playGame: {path: 'play/game'},
  profile: {path: 'profile/:username'},
  followers: {path: 'profile/:username/followers'},
  account: {path: 'account'},
  messages: {path: 'messages'},
  messagesUser: {path: 'messages/:username'},
  inviteFriend: {path: 'invite-friend'},
  refer: {path: 'refer/:referUsername'},
  verifyIdentity: {path: 'verify-identity'},
  notificationCenter: {path: 'notification-center'},
  promo: {path: 'promo/:promoId'},
  login: {path: 'login'},
  verifyAccount: {path: 'verify/:token/:userId'},
  forgotPassword: {path: 'forgot-password'},
  signup: {path: 'signup'},
  terms: {path: 'terms'},
  privacy: {path: 'privacy'},
  cookies: {path: 'cookies'},
  recoverAccount: {path: 'recover-account/:hash'},
  landingWelcome: {path: 'landing/:welcomeAlias'},
  blogTag: {path: 'blog/tag/:tag'},
  blogPost: {path: 'blog/:uid', prerender: true},
  blogHome: {path: 'blog', prerender: true},
  roulette: {path: 'roulette'},
  challenges: {path: 'challenges'},
  lastPrizesDistributed: {path: 'last-prizes-distributed'},
  error: {path: 'error'},
  maintenanceMode: {path: 'maintenance-mode'},
};

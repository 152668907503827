import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { AppMutationTypes } from './mutations';
import { AppState } from './state';
import { app } from '@/main';
import { AuthActionTypes } from '@/store/modules/auth/actions';

export const AppActionTypes = {
  SET_LOADING: '@app/SET_LOADING',
  SET_APP_CONFIG: '@app/SET_APP_CONFIG',
  SET_DEVICE: '@app/SET_DEVICE',
  SET_MODAL_PROPS: '@app/SET_MODAL_PROPS',
  SET_NEXT_URL: '@app/SET_NEXT_URL',
  SET_PAGE_DATA: '@app/SET_PAGE_DATA',
  SET_BREADCRUMB: '@app/SET_BREADCRUMB',
  SET_IS_PWA_INSTALLED: '@app/SET_IS_PWA_INSTALLED',
  INIT_APP: '@app/INIT_APP',
  INIT_GAME: '@app/INIT_GAME',
  SET_TOP_MODAL_HEIGHT: '@app/SET_TOP_MODAL_HEIGHT',
  SET_ADS_LOADED: '@app/SET_ADS_LOADED',
  SET_SHOW_ADS: '@app/SET_SHOW_ADS',
  SET_MAINTENANCE_MODE: '@app/SET_MAINTENANCE_MODE',
};

const actions: ActionTree<AppState, State> = {
  [AppActionTypes.SET_LOADING]({commit}, loading) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_LOADING, loading);
      resolve(true);
    });
  },
  [AppActionTypes.SET_APP_CONFIG]({commit}, data) {
    return new Promise((resolve) => {
      const {theme, config} = data;
      commit(AppMutationTypes.SET_CONFIG, config);
      commit(AppMutationTypes.SET_THEME, theme);
      resolve(true);
    });
  },
  [AppActionTypes.SET_DEVICE]({commit}, device) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_DEVICE, device);
      resolve(true);
    });
  },
  [AppActionTypes.SET_MODAL_PROPS]({commit}, modalProps) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_MODAL_PROPS, modalProps);
      resolve(true);
    });
  },
  [AppActionTypes.SET_NEXT_URL]({commit}, nextUrl) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_NEXT_URL, nextUrl);
      resolve(true);
    });
  },
  [AppActionTypes.SET_PAGE_DATA]({commit}, pageData) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_PAGE_DATA, pageData);
      resolve(true);
    });
  },
  [AppActionTypes.SET_BREADCRUMB]({commit}, breadcrumb) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_BREADCRUMB, breadcrumb);
      resolve(true);
    });
  },
  [AppActionTypes.SET_IS_PWA_INSTALLED]({commit}, isPwaInstalled) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_IS_PWA_INSTALLED, isPwaInstalled);
      resolve(true);
    });
  },
  [AppActionTypes.INIT_APP]({dispatch}) {
    return new Promise((resolve) => {
      if(app.$store.getters.isLoggedIn && app.$store.getters.hasUserSource){
        app.$http.user.postUserSource(app.$store.state.userSource);
      }
      app.$http.config.getInitApp().then(({data}) => {
        if (data?.data?.is_user_banned === true) {
          dispatch(AuthActionTypes.LOGOUT);
        }
      }).catch(() => {/** Nothing to do */});
      resolve(true);
    });
  },
  [AppActionTypes.INIT_GAME]({commit}, game) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_GAME, game);
      resolve(true);
    });
  },
  [AppActionTypes.SET_TOP_MODAL_HEIGHT]({commit}, topModalHeight) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_TOP_MODAL_HEIGHT, topModalHeight);
      resolve(true);
    });
  },
  [AppActionTypes.SET_ADS_LOADED]({commit}, adsLoaded) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_ADS_LOADED, adsLoaded);
      resolve(true);
    });
  },
  [AppActionTypes.SET_SHOW_ADS]({commit}, showAds) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_SHOW_ADS, showAds);
      resolve(true);
    });
  },
  [AppActionTypes.SET_MAINTENANCE_MODE]({commit}, maintenanceMode) {
    return new Promise((resolve) => {
      commit(AppMutationTypes.SET_MAINTENANCE_MODE, maintenanceMode);
      resolve(true);
    });
  },
};

export default actions;
